import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import Measure, { IMeasure } from "../../shared/models/Measure";
import {
    q4EmpRating,
  q4FinalRating,
  q4SuperRating,
  rateColor,
  semester1EmpRating,
  semester1FinalRating,
  semester1SuperRating,
} from "../shared/functions/Scorecard";
import AppStore from "../../shared/stores/AppStore";
import AppApi from "../../shared/apis/AppApi";
import { IScorecardMetadata } from "../../shared/models/ScorecardMetadata";

interface IRatingProps {
  measures: IMeasure[];
  store: AppStore; // Assuming AppStore is correctly imported and define
  api: AppApi;
  meta: IScorecardMetadata [];
}

// Function to compute ratings
export const computeMidtermRatings = (
  measures: IMeasure[],
  store: AppStore,
  meta: IScorecardMetadata[]
) => {
  console.log("Computing ratings with measures:", measures);


  const me = store.auth.meJson?.uid || "";


  const $measures = measures.map((measure) => measure);

  const firstMeasureUid = measures[0]?.uid;
  console.log("User ID:", me);
  console.log("Mapped measures:", $measures);

  const objectives = store.objective.all
    .filter((obj) => obj.asJson.uid === firstMeasureUid)
    .map((obj) => obj.asJson);

  console.log("Objectives:", objectives);

  console.log("All metadata", meta);

const scorecardMetaData = meta
  .map((m) => m)
  .find((m) => m.uid === firstMeasureUid);


  console.log("Scorecard metadata in compute:", scorecardMetaData);
console.log("Ojective", objectives);
console.log("Measuures", measures);

  const rating1 = semester1EmpRating($measures, objectives, scorecardMetaData);
  const rating2 = semester1SuperRating(
    $measures,
    objectives,
    scorecardMetaData
  );
  const rating3 = semester1FinalRating(
    $measures,
    objectives,
    scorecardMetaData
  );

  console.log("Computed ratings:", rating1, rating2, rating3);

  return {
    rating1: rating1.toFixed(2),
    rating2: rating2.toFixed(2),
    rating3: rating3.toFixed(2),
    uid:firstMeasureUid
  };
};
export const computeFinalRatings = (
  measures: IMeasure[],
  store: AppStore,
  meta: IScorecardMetadata[]
) => {
  console.log("Computing ratings with measures:", measures);

  const me = store.auth.meJson?.uid || "";

  const $measures = measures.map((measure) => measure);

  const firstMeasureUid = measures[0]?.uid;
  console.log("User ID:", me);
  console.log("Mapped measures:", $measures);

  const objectives = store.objective.all
    .filter((obj) => obj.asJson.uid === firstMeasureUid)
    .map((obj) => obj.asJson);

  console.log("Objectives:", objectives);

  console.log("All metadata", meta);

  const scorecardMetaData = meta
    .map((m) => m)
    .find((m) => m.uid === firstMeasureUid);

  console.log("Scorecard metadata in compute:", scorecardMetaData);
  console.log("Ojective", objectives);
  console.log("Measuures", measures);

   const rating1 = q4EmpRating($measures, objectives, scorecardMetaData);
   const rating2 = q4SuperRating($measures, objectives, scorecardMetaData);
   const rating3 = q4FinalRating($measures, objectives, scorecardMetaData);
  console.log("Computed ratings:", rating1, rating2, rating3);

  return {
    finalRating1: rating1.toFixed(2),
    finalRating2: rating2.toFixed(2),
    finalRating3: rating3.toFixed(2),
  };
};

// Exported function that returns ratings based on measures and store
const ScorecardRatings: React.FC<IRatingProps> = ({ measures, store,meta }) => {
  const { rating1, rating2, rating3 } = computeMidtermRatings(measures, store,meta);

  const q2_e_css = rateColor(parseFloat(rating1), true);
  const q2_s_css = rateColor(parseFloat(rating2), true);
  const q2_f_css = rateColor(parseFloat(rating3), true);

  return (
    <div className="measure-rating-table">
      <table>
        <thead className="header">
          <tr>
            <th>E-Rating</th>
            <th>S-Rating</th>
            <th>F-Rating</th>
          </tr>
        </thead>
        <tbody>
          <tr className="row">
            <td className={`value ${q2_e_css}`}>{rating1}</td>
            <td className={`value ${q2_s_css}`}>{rating2}</td>
            <td className={`value ${q2_f_css}`}>{rating3}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default observer(ScorecardRatings);
